import React from 'react';
import { graphql } from 'gatsby';
import PageContent from './components/Page';

import Layout, { Content } from './components/Layout';
import HeroHeader from './components/heroheader';
import ContentNavigation from './components/Navigation/Content';
import {
  Container as BlogContainer,
  Entry as BlogEntry,
} from './components/Blog';
import createPersistedState from 'use-persisted-state';
import { Currencies } from './components/config/currency';

const useCurrency = createPersistedState('currency');

const CURRENCY_SYMBOLS = {};
Currencies.supported.forEach(({ code, symbol }) => {
  CURRENCY_SYMBOLS[code] = symbol;
});

export const query = graphql`
  query ProgrammeQuery($id: Int!, $pageId: Int!) {
    strapiProgrammes(strapiId: { eq: $id }) {
      strapiId
      Duration
      Title
      Theme
      Pipedrive_ID
      Category {
        id
      }
      Cost {
        EUR
        GBP
        USD
        NZD
        AUD
      }
      Deposit {
        EUR
        GBP
        USD
        NZD
        AUD
      }
      CTA {
        Text
        URL
        Button
      }
      Key_Visual {
        publicURL
      }
      Eligibility {
        Title
      }
    }
    strapiPages(strapiId: { eq: $pageId }) {
      Display_Title
      Headline
      Slug
      Introduction
      Navigtion_Label
      Metadata {
        id
        Title
        Description
      }
      Key_Visual {
        publicURL
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
        }
      }
      CTA {
        Text
        URL
        Button
      }
      Cost {
        GBP
        EUR
        USD
        AUD
        NZD
      }
      Key_Visual {
        publicURL
      }
      CTA {
        Text
        URL
        Button
      }
      Content {
        id
        Title
        Subtitle
        Content
        ButtonText
        CTA_Text
        CTA_URL
        Colour
        IconLayout
        ImageLayout
        Introduction
        YouTube_URL
        URL
        Decoration
        ContentLayout
        ColorVariant
        Restriction
        Button {
          Text
          URL
        }
        Buttons {
          Text
          URL
        }
        SideBySideDisplay
        Travel_With_Confidence_Title
        Layout
        ReviewsTitle
        ReviewsSubtitle
        BackgroundType
        Brush {
          Stroke_Type
        }
        Key_Visual {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
        Galleries {
          Title
          id
        }
        JobRoles {
          Title
        }
        Questions {
          Answer
          Question
          id
        }
        Steps {
          Title
          Subtitle
          Content
          id
        }
        Locations {
          Camp
          Location
          Key_Visual {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
          Button {
            Text
            URL
          }
        }
        Icons {
          Content
          Key_Visual {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
        Quote_Tabs {
          Tab_Title
          Quotes {
            id
            Content
            Person
            Experience
          }
        }
        Blogs {
          id
          Title
          Intro
          Content
          Category
          Key_Visual {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
        Quotes {
          id
          Content
          Person
          Experience
        }
        TextQuotes {
          id
          Content
          Person
          Experience
        }
        Grid_Items {
          Question
          Answer
        }
        Item1 {
          Content
          Title
          Subtitle
          Key_Visual {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
          Button {
            id
            Text
            URL
          }
        }
        Item2 {
          Content
          Title
          Subtitle
          Key_Visual {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
          Button {
            id
            Text
            URL
          }
        }
        Product_One {
          Title
          Subtitle
          Introduction
          Details
          First_Payment {
            Product_Title
            Product {
              id
            }
          }
          Desposit {
            Product_Title
            Product {
              id
            }
          }
          Second_Payment {
            Product_Title
            Product {
              id
            }
          }
          Final_Text
          Include_Total
          Button {
            Text
            URL
          }
        }
        Product_Two {
          Title
          Subtitle
          Introduction
          Details
          First_Payment {
            Product_Title
            Product {
              id
            }
          }
          Desposit {
            Product_Title
            Product {
              id
            }
          }
          Second_Payment {
            Product_Title
            Product {
              id
            }
          }
          Final_Text
          Include_Total
          Button {
            Text
            URL
          }
        }
        Items {
          Content
          Title
          Subtitle
          Key_Visual {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
          Button {
            Text
            URL
          }
        }
      }
    }
  }
`;

const Programme = ({ data, pageContext, location }) => {
  const [activeCurrency] = useCurrency(Currencies.default);

  const programme = data.strapiProgrammes;
  const page = data.strapiPages;
  const programmeContext = pageContext;
  const navigation = pageContext.navigation;

  const { blogs, homepage } = pageContext;
  const title = [programme.Title];
  let keyVisualUrl = programme.Key_Visual
    ? programme.Key_Visual.publicURL
    : '/images/destinations.jpg';
  let cost = programme.Cost;
  const theme = programme.Theme || 'default';

  if (page) {
    if (!homepage) {
      title.push(page.Headline || page.Display_Title);
    }
    keyVisualUrl = page.Key_Visual ? page.Key_Visual.publicURL : keyVisualUrl;
    cost = page.Cost || cost;
  }

  if (blogs) {
    title.push('Blogs');
  }
  const metadata = programme.Metadata || { Title: false };

  return (
    <Layout
      title={metadata.Title || title.join(' ')}
      desc={metadata.Description || ''}
      noIndex={metadata.noIndex}
    >
      <HeroHeader
        theme={theme}
        title={title.join(' ')}
        cta={programme.CTA}
        backgroundUrl={keyVisualUrl}
        infoData={{
          pipedriveId: programme.Pipedrive_ID,
          cost: `${CURRENCY_SYMBOLS[activeCurrency]} ${
            cost && cost[activeCurrency] ? cost[activeCurrency] : '-'
          }`,
          deposit: `${CURRENCY_SYMBOLS[activeCurrency]} ${
            programme.Deposit ? programme.Deposit[activeCurrency] : '-'
          }`,
          duration: programme.Duration,
          eligibility: programme.Eligibility.map(elem => elem.Title).join(', '),
          navigation,
          parentSlug: programmeContext.slug,
        }}
      />
      <ContentNavigation
        theme={theme}
        links={navigation}
        currentPathName={location.pathname}
      />
      <Content>
        {page &&
          page.Content &&
          page.Content.map((content, index) => (
            <PageContent theme={theme} key={index} content={content} />
          ))}
        {blogs && (
          <BlogContainer>
            {blogs.map(blog => (
              <BlogEntry key={blog.strapiId} blog={blog} />
            ))}
          </BlogContainer>
        )}
      </Content>
    </Layout>
  );
};

export default Programme;
